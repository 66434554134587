<script setup>
import Partner from '@/assets/js/src/modules/partner/_components/PartnerList.vue'
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'
const {t,} = useTranslation()
</script>

<template>
    <div
        id="partner"
        class="bs-home__partner"
    >
        <div class="stroke bs-h xl float-right"></div>
        <h2>{{ t('async.home.partner') }}</h2>
        <v-row
            no-gutters
            class="fill-height"
        >
            <v-col
                cols="12"
                md="8"
                offset-md="2"
            >
                <div class="bs-home__partner__box__desc">
                    <p>{{ t('async.home.partner_intro') }}</p>
                </div>
            </v-col>
        </v-row>
        <v-row
            no-gutters
            class="fill-height bs-home__partner__box"
        >
            <v-col
                cols="12"
                md="10"
                offset-md="1"
            >
                <partner :shadow="false"></partner>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="scss">
.bs-app .bs-home {
    &__partner {
        position: relative;
        z-index: 3;
        width: 100%;
        padding: 84px 0 90px;
        background: map-deep-get($bs-color, white);

        @include dark {
            background: map-deep-get($bs-color, dark, white);
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            &__box {
                padding: 0 24px;
            }
        }

        &__box__desc {
            margin-top: 80px;

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                margin-top: 24px;
                padding: 0 24px;
            }
        }

        h2 {
            width: 100%;
            margin: 0;
            padding: 0;
            color: map-deep-get($bs-color, black);

            @include dark {
                color: map-deep-get($bs-color, dark, black);
            }

            font-weight: 200;
            font-size: 48px;
            text-align: center;
        }
    }
}
</style>
