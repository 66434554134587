<template>
    <div class="bs-home">
        <home-autocomplete
            :status="allAsyncLoaded"
            :progress="progress"
            :class="{'bs-home__autocomplete--banner-visible':banner}"
        ></home-autocomplete>
        <v-no-ssr>
            <home-sidenav
                v-if="allAsyncLoaded"
                :items="navItems"
            ></home-sidenav>
        </v-no-ssr>
        <home-features v-if="allAsyncLoaded"></home-features>
        <home-testimonials
            v-if="allAsyncLoaded && meta.testimonials.length > 0"
            :items="meta.testimonials"
        ></home-testimonials>
        <v-lazy
            transition="fade-transition"
        >
            <home-graphic-of-the-day
                :messages-loaded="messagesLoaded"
            />
        </v-lazy>
        <home-contact v-if="allAsyncLoaded"></home-contact>
        <home-about v-if="allAsyncLoaded"></home-about>
        <home-partner v-if="allAsyncLoaded"></home-partner>
        <home-footer
            v-if="allAsyncLoaded"
            :locale="locale"
            :heading="messagesLoaded ? t('async.home.footer') : ''"
        ></home-footer>
    </div>
</template>

<script>
import xl from '@/assets/js/src/style/json/variables'
import xs from '@/assets/js/src/style/json/variables-xs'
import HomeAutocomplete from '@/assets/js/src/modules/home/_components/HomeAutocomplete.vue'
import HomeFeatures from '@/assets/js/src/modules/home/_components/HomeFeatures.vue'
import HomeSidenav from '@/assets/js/src/modules/home/_components/HomeNavigation.vue'
import HomeGraphicOfTheDay from '@/assets/js/src/modules/home/_components/HomeGraphicOfTheDay.vue'
import HomeContact from '@/assets/js/src/modules/home/_components/HomeContact.vue'
import HomeAbout from '@/assets/js/src/modules/home/_components/HomeAbout.vue'
import HomePartner from '@/assets/js/src/modules/home/_components/HomePartner.vue'
import HomeFooter from '@/assets/js/src/modules/home/_components/HomeFooter.vue'
import HomeTestimonials from '@/assets/js/src/modules/home/_components/HomeTestimonials.vue'
import {debounce,} from '@/assets/js/src/util/uiTools'
import {getActivePinia, storeToRefs,} from 'pinia'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useHomeApi,} from '@/assets/js/src/modules/home/_composables/useHomeApi'
import {useHeadInfo,} from "@/assets/js/src/modules/home/_composables/useHeadInfo"
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'

const AUTOCOMPLETE_ID = 'bs-home__autocomplete-wrapper'
const HEADER_ID = '.bs-header-wrapper'

export default {
    name: 'Home',
    components: {
        HomeTestimonials,
        HomeAutocomplete,
        HomeFeatures,
        HomeSidenav,
        HomeGraphicOfTheDay,
        HomeContact,
        HomeAbout,
        HomePartner,
        HomeFooter,
    },
    beforeRouteEnter (to,from,next){
        let rootState = getActivePinia().state.value
        rootState.appUi.home = true

        next()
    },
    setup () {
        let activePinia = getActivePinia()

        // AppUiStore
        const appUiStore = useAppUiStore(activePinia)
        const { menu, banner, } = storeToRefs(appUiStore)
        const {
            backgroundFade,
            homeLogoHidden,
            homeTransition,
            homeTop,
        } = storeToRefs(appUiStore)

        // LangStore
        const langStore = useLangStore(activePinia)
        const { locale, } = storeToRefs(langStore)

        useHeadInfo()

        return {
            // AppUiStore
            menu,
            banner,
            backgroundFade,
            homeLogoHidden,
            homeTransition,
            homeTop,
            // LangStore
            locale,
            //Composables
            ...useHomeApi(),
            ...useTranslation(),
        }
    },
    data: () => ({
        initialized: false,
        elem: null,
        optionElem: null,
        logo: false,
        offsetHeight: 0,
        offsetTop: 0,
        headerHeight: 0,
        bannerHeight: 0,
        progress: false,
        hide: true,
        show: true,
        height: 0,
        xl,
        xs,
        navItems: [],
    }),
    watch: {
        homeTransition: function (newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                this.homeTransition = true
                this.progress = true
            }
        },
        allAsyncLoaded: {
            immediate: true,
            handler: function (newVal) {
                if (newVal) {
                    this.navItems = [
                        {
                            title: 'Top',
                            target: '#top',
                            offset: 0,
                        },
                        {
                            title: this.t('async.home.features.heading'),
                            target: '#features',
                            offset: 0,
                        },
                    ]

                    if (this.meta.testimonials?.length > 0) {
                        this.navItems.push(
                            {
                                title: this.t('async.home.testimonials'),
                                target: '#testimonials',
                                offset: 0,
                                show: 'bs-home__testimonials__animate',
                                hide: 'bs-home__testimonials__animate__back',
                                duration: 2000,
                            }
                        )
                    }

                    this.navItems.push(
                        {
                            title: 'Vers des Tages',
                            target: '#graphic-of-the-day',
                            offset: 0,
                            show: 'bs-home__hash-wrapper__animate',
                            hide: 'bs-home__hash-wrapper__animate__back',
                            duration: 2500,
                        }
                    )

                    this.navItems.push(
                        {
                            title: this.t('async.home.contact.heading'),
                            target: '#contact',
                            offset: 0,
                        }
                    )

                    this.navItems.push(
                        {
                            title: this.t('async.home.about.heading'),
                            target: '#about',
                            offset: -42,
                            show: 'bs-home__about__animate',
                            hide: 'bs-home__about__animate__back',
                            duration: 2500,
                        }
                    )

                    this.navItems.push(
                        {
                            title: this.t('async.home.partner'),
                            target: '#partner',
                            offset: 0,
                        }
                    )
                }
            },
        },
        '$vuetify.display.smAndDown': {
            handler: function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.$nextTick(() => {
                        this.hide = true
                        this.show = true
                        this.loadInitialValues()
                    })
                }
            },
        },
    },
    mounted () {
        if (window.pageYOffset === 0) {
            document.querySelector(HEADER_ID).style.transition = 'all 0s'
            document.querySelector(HEADER_ID).classList.add('bs-home__header-wrapper--top')
            document.querySelector(HEADER_ID).style.transition = null
        }
        this.$nextTick(() => {
            this.loadInitialValues()
        })
        this.debounceHandleScroll = debounce(this.handleScroll)
        document.addEventListener('scroll', this.debounceHandleScroll)
    },
    beforeUnmount () {
        document.removeEventListener('scroll', this.debounceHandleScroll)
    },
    unmounted () {
        this.elem = null
        this.optionElem = null
        this.$options.parent = null

        this.homeTop = false
        this.homeTransition = false
        this.backgroundFade = true
    },
    methods: {
        handleScroll () {

            if (this._isDestroyed) {
                document.removeEventListener('scroll', this.debounceHandleScroll)

                return
            }

            if (!this.initialized) {
                this.loadInitialValues()
            }

            if (!this.menu && this.initialized) {
                // Animation Übergang Autocomplete from pos absolute to fixed header on top
                if ((this.offsetTop - window.pageYOffset) < this.headerHeight) {
                    if (this.show) {
                        this.show = false
                        this.hide = true
                        this.elem.style.transition = 'all 0s ease'
                        this.elem.style.opacity = 0
                        this.optionElem = this.optionElem ?? document.querySelector('.bs-header__option-wrapper')
                        if(this.optionElem) {
                            this.optionElem.style.transition = 'all 0s ease'
                            this.optionElem.style.opacity = 0
                        }
                        this.homeTop = true
                        if (this.logo) {
                            this.homeLogoHidden = true
                        }
                        setTimeout(() => {
                            if (this.elem) {
                                this.elem.style.transition = globalThis.isIE ? '' : null
                                this.elem.style.opacity = globalThis.isIE ? '' : null
                            }
                            if(this.optionElem) {
                                this.optionElem.style.transition = globalThis.isIE ? '' : null
                                this.optionElem.style.opacity = globalThis.isIE ? '' : null
                            }
                        }, 200)
                    }
                } else { // Animation Übergang Autocomplete to pos absolute from fixed header on top
                    if (this.hide) {
                        this.show = true
                        this.hide = false
                        this.elem.style.opacity = 0
                        this.optionElem = this.optionElem ?? document.querySelector('.bs-header__option-wrapper')
                        if(this.optionElem) {
                            this.optionElem.style.transitionDuration = '0s'
                            this.optionElem.style.opacity = 0
                        }
                        if (this.logo) {
                            this.homeLogoHidden = false
                        }
                        setTimeout(() => {
                            this.homeTop = false
                            if (this.elem) {
                                this.elem.style.opacity = globalThis.isIE ? '' : null
                            }
                            if(this.optionElem) {
                                this.optionElem.style.transitionDuration = globalThis.isIE ? '' : null
                                this.optionElem.style.opacity = globalThis.isIE ? '' : null
                            }
                        }, 200)
                    }
                }
            }
        },
        loadInitialValues () {
            this.elem = document.getElementById(AUTOCOMPLETE_ID)

            if (!this.elem) {
                return
            }

            this.offsetHeight = this.elem.offsetHeight
            this.offsetTop = window.innerHeight * 0.42
            this.headerHeight = document.querySelector(HEADER_ID).offsetHeight

            if (document.documentElement.clientWidth <= parseInt(this.xs.breakpointValue)) {
                this.bannerHeight = parseInt(this.xs.banner.height)
                this.logo = true
            } else {
                this.bannerHeight = parseInt(this.xl.banner.height)
                this.logo = false
            }

            if ((this.offsetTop - window.pageYOffset) < this.headerHeight) {
                if (this.show) {
                    this.show = false
                    this.hide = true
                    if (this.logo) {
                        this.homeLogoHidden = true
                    } else {
                        this.homeLogoHidden = false
                    }
                    this.homeTop = true
                }
            } else {
                if (this.hide) {
                    this.show = true
                    this.hide = false
                    this.homeTop = false

                    if (this.logo) {
                        this.homeLogoHidden = false
                    }
                }
            }

            this.initialized = true
        },
    },
}
</script>

<style lang="scss">
    .bs-app main.v-main {
        overflow: hidden;
    }

    .bs-app .bs-home {
        max-width: 100vw;

        &__logo {
            max-width: 300px;
            opacity: 1;

            &--hidden {
                max-width: 0;
                opacity: 0;
            }
        }

        .spacer {
            width: 100%;
            height: 1000px;
            background: #efefef;
        }
    }

    @media print {
        .bs-app main.v-main {
            overflow: visible;
        }
    }
</style>
