<template>
    <div
        id="features"
        class="bs-home__features"
    >
        <h2>
            {{ t('async.home.features.heading') }}
        </h2>
        <v-row
            no-gutters
            class="fill-height"
        >
            <v-col
                cols="12"
                md="4"
                offset-md="2"
            >
                <div class="bs-home__features__feature-box">
                    <h3>
                        <v-icon size="large">
                            bs:$vuetify.icons.mdiBookOpenPageVariant
                        </v-icon>
                        {{ t(`async.home.features.${isDeLocale ? 'bible-and-comparison' : 'bible'}.heading`) }}
                    </h3>
                    <p>{{ t(`async.home.features.${isDeLocale ? 'bible-and-comparison' : 'bible'}.text`) }}</p>
                </div>
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
                <div class="bs-home__features__feature-box">
                    <h3>
                        <v-icon size="large"><niko-svg monochrome></niko-svg></v-icon>
                        {{ t('async.home.features.nicodemus-ai.heading') }}
                    </h3>
                    <p>{{ t('async.home.features.nicodemus-ai.text') }}</p>
                </div>
            </v-col>
        </v-row>
        <v-row
            no-gutters
            class="fill-height"
        >
            <v-col
                cols="12"
                md="4"
                offset-md="2"
            >
                <div class="bs-home__features__feature-box">
                    <h3>
                        <v-icon size="large">
                            bs:$vuetify.icons.mdiMagnify
                        </v-icon>
                        {{ t('async.home.features.search.heading') }}
                    </h3>
                    <p>{{ t('async.home.features.search.text') }}</p>
                </div>
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
                <div class="bs-home__features__feature-box">
                    <h3>
                        <v-icon
                            v-if="isDeLocale"
                            size="large"
                        >
                            bs:$vuetify.icons.mdiCalendarCheck
                        </v-icon>
                        <v-icon
                            v-else
                            size="large"
                        >
                            bs:$vuetify.icons.mdiViewColumn
                        </v-icon>
                        {{ t(`async.home.features.${isDeLocale ? 'reading-plans' : 'translation-comparison'}.heading`) }}
                    </h3>
                    <p>{{ t(`async.home.features.${isDeLocale ? 'reading-plans' : 'translation-comparison'}.text`) }}</p>
                </div>
            </v-col>
        </v-row>
        <v-row
            no-gutters
            class="fill-height"
        >
            <v-col
                cols="12"
                md="4"
                offset-md="2"
            >
                <div class="bs-home__features__feature-box">
                    <h3>
                        <v-icon size="large">bs:$vuetify.icons.mdiPencil</v-icon>
                        {{ t('async.home.features.personalize.heading') }}
                    </h3>
                    <p>{{ t('async.home.features.personalize.text') }}</p>
                </div>
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
                <div class="bs-home__features__feature-box">
                    <h3>
                        <v-icon size="large">bs:$vuetify.icons.mdiTag</v-icon>
                        {{ t('async.home.features.tags.heading') }}
                    </h3>
                    <p>{{ t('async.home.features.tags.text') }}</p>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {getActivePinia, storeToRefs,} from "pinia"
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'
import NikoSvg from '@/assets/js/src/modules/chat/_components/NikoSvg.vue'

let activePinia = getActivePinia()

const langStore = useLangStore(activePinia)
const {isDeLocale,} = storeToRefs(langStore)
const {t,} = useTranslation()
</script>

<style lang="scss">
.bs-app .bs-home {
    &__features {
        position: relative;
        z-index: 2;
        width: 100%;
        padding: 92px 0 110px;
        background: map-deep-get($bs-color, greyLight);

        @include dark {
            background: map-deep-get($bs-color, dark, greyLight);
        }

        h2 {
            width: 100%;
            margin: 0 0 52px;
            padding: 0;
            color: map-deep-get($bs-color, black);

            @include dark {
                color: map-deep-get($bs-color, dark, black);
            }

            font-weight: 200;
            font-size: 48px;
            text-align: center;
        }

        & > div:not(:first-of-type) {
            margin-top: 4px;

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                margin-top: 0;
            }
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            & > div > div {
                margin-top: 4px;
            }

            & > div:first-of-type > div:first-of-type {
                margin-top: 0;
            }
        }

        &__feature-box {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;
            margin: 2px;
            padding: 48px;
            background: map-deep-get($bs-color, white);
            border-radius: map-deep-get($bs-xl, autocomplete, inputRadius);

            @include dark {
                background: map-deep-get($bs-color, dark, white);
            }

            @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                padding: 24px;
            }

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                margin: 0 16px;
                padding: 16px;
            }

            h3 {
                display: flex;
                align-items: self-start;
                margin: 0 0 16px;
                color: map-deep-get($bs-color, black);
                font-weight: 700;
                font-size: 24px;

                @include dark {
                    color: map-deep-get($bs-color, dark, black);
                }

                svg {
                    width: 36px !important;
                    height: 36px !important;
                    fill: map-deep-get($bs-color, black) !important;

                    @include dark {
                        fill: map-deep-get($bs-color, dark, black) !important;
                    }
                }

                .v-icon {
                    width: 36px !important;
                    height: 36px !important;
                    margin-right: 8px;
                }
            }

            p {
                color: map-deep-get($bs-color, black);

                @include dark {
                    color: map-deep-get($bs-color, dark, black);
                }

                font-weight: normal;
                font-size: 16px;
            }
        }

        & > div:last-of-type > div:last-of-type {
            h3 {
                svg {
                    height: 36px !important;
                }
            }
        }
    }
}
</style>
