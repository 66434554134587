<template>
    <div
        id="graphic-of-the-day"
        class="bs-home__graphic-wrapper"
    >
        <div class="stroke bs"></div>
        <div
            class="stroke bs float-right xl d-none d-md-block"
            style="top: 70%; right: -5%; left: auto;"
        ></div>
        <div
            class="stroke light xxl d-none d-md-block"
            style="top: 30%; right: auto; left: 55%;"
        ></div>
        <div
            class="stroke light xxl d-none d-md-block"
            style="top: 75%; right: auto; left: -5%;"
        ></div>
        <div
            v-if="loaded && messagesLoaded"
            class="bs-home__graphic-wrapper__graphic"
        >
            <h2>
                {{ t('async.home.day_graphic') }}
            </h2>
            <v-row class="bs-home__graphic-wrapper__graphic__img">
                <v-col
                    xl="4"
                    md="6"
                    cols="12"
                    offset-xl="4"
                    offset-md="3"
                >
                    <router-link
                        :to="{path:`/verse-of-the-day`}"
                    >
                        <graphic-image
                            :graphic="graphic"
                            :start="true"
                        >
                        </graphic-image>
                    </router-link>
                </v-col>
            </v-row>
        </div>
        <div
            v-if="loaded && messagesLoaded"
            class="bs-home__graphic-wrapper__link"
        >
            <v-btn
                class="flat-btn hidden-print-only"
                color="secondary"
                variant="text"
                :title="t('async.common.button.bible_text')"
                :to="to"
            >
                <v-icon>bs:$vuetify.icons.mdiBookOpenPageVariant</v-icon>&nbsp;
                {{ t('async.common.button.bible_text') }}
            </v-btn>
            <v-btn
                class="flat-btn hidden-print-only"
                color="secondary"
                variant="text"
                :title="t('async.newsletter.subscribe')"
                :to="{path: `/verse-of-the-day#abo`}"
            >
                <v-icon>bs:$vuetify.icons.mdiBellRing</v-icon>&nbsp;
                {{ t('async.newsletter.subscribe') }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import GraphicImage from '@/assets/js/src/modules/resource/_components/GraphicImage.vue'
import {mapActions, mapState,} from 'pinia'
import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {useHomeStore,} from '@/assets/js/src/modules/home/_pinia/home'
import {useBibleRefs,} from '@/assets/js/src/util/composables/useBibleRefs'
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"

export default {
    name: 'HomeGraphicOfTheDay',
    components: {GraphicImage,},
    props: {
        messagesLoaded: {
            type: Boolean,
            default: false,
        },
    },
    setup () {
        return {
            ...useBibleRefs(),
            ...useTranslation(),
        }
    },
    data: () => ({
        loaded: false,
        graphic: {},
        random: (Math.floor(Math.random() * (10 - 1)) + 1),
    }),
    computed: {
        ...mapState(useBibleStore,[
            'selectedBibles',
        ]),
        to: function () {
            if (this.loaded) {
                let abbr = encodeURIComponent(this.selectedBibles.join('.'))

                return '/' + abbr + '/' + this.link.replace(' ', '')
            } else {
                return '#'
            }
        },
        link: function () {
            if (this.loaded) {
                return this.getRefByCanonicals({canonicals: [ this.graphic.canonicals[0], ], })
            } else {
                return ''
            }
        },

    },
    async mounted () {
        this.graphic = await this.fetchGraphic()

        if (typeof (this.graphic || {}).canonicals !== 'undefined') {
            this.loaded = true
        }
    },
    methods: {
        ...mapActions(useHomeStore,[
            'fetchGraphic',
        ]),
    },
}
</script>

<style lang="scss">
.bs-app .bs-home {
    &__graphic-wrapper {
        position: relative;
        z-index: 3;
        padding: 150px 0 120px;
        background: map-deep-get($bs-color, white);

        @include dark {
            background: map-deep-get($bs-color, dark, white);
        }

        border-top: 4px solid map-deep-get($bs-color, greyLight);

        @include dark {
            border-top: 4px solid map-deep-get($bs-color, dark, greyLight);
        }

        &__graphic {
            position: relative;
            z-index: 3;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__img {
                width: 100%;
                margin-top: 24px;
            }

            h2 {
                width: 100%;
                margin: 0;
                padding: 0;
                color: map-deep-get($bs-color, black);

                @include dark {
                    color: map-deep-get($bs-color, dark, black);
                }

                font-weight: 200;
                font-size: 48px;
                text-align: center;
            }

            p {
                position: relative;
                margin: 0;
                color: #fff;
                font-weight: 900;
                font-size: 120px;
                line-height: 120px;
                text-transform: uppercase;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    font-size: 50px;
                    line-height: 50px;
                }

                span:first-of-type {
                    position: relative;
                    z-index: 2;
                }
            }

            &__back {
                position: absolute;
                top: 0;
                left: 20px;
                z-index: 1;
                width: calc(100% - 40px);
                height: 100%;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    left: 10px;
                    width: calc(100% - 18px);
                }
            }

            @each $color_name, $color_value in map-deep-get($bs-color, tag, map) {
                &__back--#{$color_name} {
                    background: $color_value;
                }
            }
        }

        &__link {
            margin-top: 24px;
            text-align: center;
        }
    }
}
</style>