<template>
    <div
        id="testimonials"
        class="bs-home__testimonials"
    >
        <div class="stroke bs xl float-left"></div>
        <v-row
            class="fill-height"
        >
            <v-col
                cols="12"
                md="8"
                offset-md="2"
                class="bs-home__testimonials__wrapper"
            >
                <div
                    class="bs-home__testimonials__wrapper__carousel"
                >
                    <div
                        v-for="index in indexes"
                        :key="`testimonial-screen-${items[index].id}`"
                        :style="`transform: translateX(${-1 * testimonialStep * 100}%);`"
                        :class="['bs-home__testimonials__wrapper__carousel__item',{notrans,}]"
                    >
                        <div class="bs-home__testimonials__wrapper__testimonial">
                            <div class="bs-home__testimonials__wrapper__testimonial--image">
                                <bs-v-img
                                    :src="items[index].image"
                                    :title="title(items[index])"
                                    :alt="title(items[index])"
                                />
                            </div>
                            <div class="bs-home__testimonials__wrapper__testimonial--text">
                                <p>{{ items[index].content }}</p>
                                <p>{{ title(items[index]) }}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="index in indexes.slice(0,2)"
                        :key="`testimonial-screen-clone-${items[index].id}`"
                        :style="`transform: translateX(${-1 * testimonialStep * 100}%);`"
                        :class="['bs-home__testimonials__wrapper__carousel__item',{notrans,}]"
                    >
                        <div class="bs-home__testimonials__wrapper__testimonial">
                            <div class="bs-home__testimonials__wrapper__testimonial--image">
                                <bs-v-img
                                    :src="items[index].image"
                                    :title="title(items[index])"
                                    :alt="title(items[index])"
                                />
                            </div>
                            <div class="bs-home__testimonials__wrapper__testimonial--text">
                                <p>{{ items[index].content }}</p>
                                <p>{{ title(items[index]) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <v-btn
                    v-if="items.length > 0"
                    class="elevation-0 bs-home__testimonials__wrapper__button bs-home__testimonials__wrapper__button--left"
                    :aria-label="t('async.common.button.prev')"
                    icon
                    variant="plain"
                    @click="prev()"
                >
                    <v-icon
                        :color="$vuetify.theme.current.dark ? colors.white : colors.black"
                    >
                        bs:$vuetify.icons.mdiArrowLeft
                    </v-icon>
                </v-btn>
                <v-btn
                    v-if="items.length > 0"
                    class="elevation-0 bs-home__testimonials__wrapper__button bs-home__testimonials__wrapper__button--right"
                    :aria-label="t('async.common.button.next')"
                    icon
                    variant="plain"
                    @click="next()"
                >
                    <v-icon
                        :color="$vuetify.theme.current.dark ? colors.white : colors.black"
                    >
                        bs:$vuetify.icons.mdiArrowRight
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import colors from '@/assets/js/src/style/json/colors'
import {useTouch,} from '@/assets/js/src/util/composables/useTouch'
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'

export default {
    name: 'HomeTestimonials',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        status: {
            type: Boolean,
            default: false,
        },
    },
    setup () {
        return {
            colors,
            ...useTranslation(),
            ...useTouch({
                elemSelector: '.bs-home__testimonials__wrapper__carousel',
                threshold: 72,
                goPrev: function () {
                    this.prev()
                },
                goNext: function () {
                    this.next()
                },
            }),
        }
    },
    data () {
        let indexes = []
        for (let i = 0; i < this.items.length; i++) {
            indexes.push(i)
        }

        return {
            testimonialStep: 1,
            indexes,
            notrans: false,
        }
    },
    watch: {
        testimonialStep (newValue) {
            if (newValue === this.items.length + 1) {
                setTimeout(() => {
                    this.notrans = true
                    this.testimonialStep = 1
                }, 350) // Delay for transition
            } else if (newValue === 0) {
                setTimeout(() => {
                    this.notrans = true
                    this.testimonialStep = this.items.length
                }, 350) // Delay for transition
            } else if (newValue === 1 || newValue === this.items.length) {
                setTimeout(() => {
                    this.notrans = false
                }, 50) // Delay for transition
            }
        },
    },
    mounted () {
        // Shufflen nur clientseitig!
        this.indexes.sort(function () {
            return 0.5 - Math.random()
        })
    },
    methods: {
        title (item) {
            return item.name + (typeof item.profession !== 'undefined' && item.profession.trim() !== '' ? ', ' + item.profession : '')
        },
        next () {
            this.testimonialStep++
        },
        prev () {
            this.testimonialStep--
        },
    },

}
</script>

<style lang="scss">
    .bs-app .bs-home {
        &__testimonials {
            .testimonialslidein-leave-active,
            .testimonialslidein-enter-active {
                transition: 1s;
            }

            .testimonialslidein-enter-from {
                transform: translate(-100%, 0);
            }

            .testimonialslidein-leave-to {
                transform: translate(100%, 0);
            }

            .testimonialslideout-leave-active,
            .testimonialslideout-enter-active {
                transition: 1s;
            }

            .testimonialslideout-enter-from {
                transform: translate(-100%, 0);
            }

            .testimonialslideout-leave-to {
                transform: translate(100%, 0);
            }

            position: relative;
            z-index: 2;
            padding: 115px 0 96px;
            background: map-deep-get($bs-color, white);

            @include dark {
                background: map-deep-get($bs-color, dark, white);
            }

            &__wrapper {
                position: relative;
                padding: 0 130px;
                overflow: hidden;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    position: relative;
                    padding: 0 50px;
                    overflow: hidden;
                }

                &__carousel {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    width: 100%;
                    overflow: hidden;

                    &__item {
                        flex-shrink: 0;
                        width: 100%;
                        transition: transform .3s;
                    }
                }

                &__testimonial {
                    display: flex;
                    flex-wrap: nowrap;
                    width: 100%;

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        flex-wrap: wrap;
                    }

                    &--image {
                        width: 143px;
                        padding: 0 0 0 3px;

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            display: flex;
                            justify-content: center;
                            width: 100%;
                        }

                        .v-img {
                            max-width: 100%;
                            height: auto;
                            border-radius: map-deep-get($bs-xl, autocomplete, inputRadius);

                            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                                max-width: 140px;
                            }
                        }
                    }

                    &--text {
                        width: calc(100% - 143px);
                        padding: 0 0 0 42px;

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            width: 100%;
                            margin: 16px 0 0;
                            padding: 0;
                        }

                        p {
                            hyphens: auto;
                        }

                        p:first-of-type {
                            font-weight: 200;
                            font-size: 24px;
                            line-height: 28px;
                        }

                        p:last-of-type {
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 19px;
                        }
                    }
                }

                &__button {
                    position: absolute;
                    top: 50%;
                    width: 36px;
                    height: 36px;
                    transform: translateY(-50%);

                    &--left {
                        left: 0;
                    }

                    &--right {
                        right: 0;
                    }
                }
            }
        }
    }
</style>
