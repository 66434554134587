<script setup>
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'
const {t,} = useTranslation()
</script>

<template>
    <div
        id="about"
        class="bs-home__about"
    >
        <v-row
            no-gutters
            class="fill-height"
        >
            <v-col
                cols="12"
                sm="8"
                offset-sm="2"
            >
                <div class="stroke xl float-left"></div>
                <h2 class="bs-home__about__heading">
                    {{ t('async.home.about.heading') }}
                </h2>
                <v-row
                    no-gutters
                    class="fill-height"
                >
                    <v-col
                        cols="12"
                        sm="8"
                    >
                        <div class="bs-home__about__box">
                            <div class="bs-home__about__box__description">
                                <p
                                    v-html="t('async.home.about.description')"
                                ></p>
                            </div>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-no-ssr>
                            <div class="bs-home__about__box__image">
                                <svg
                                    id="Ebene_1"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 211 119.4"
                                    style="enable-background: new 0 0 211 119.4;"
                                    xml:space="preserve"
                                    fill="currentColor"
                                >
                                    <g id="LogoClaim">
                                        <g>
                                            <path
                                                d="M73.6,98.3c-1,0-1.5,0.5-1.5,1.5v1.1c0,1,0.5,1.5,1.5,1.5h1.2c1,0,1.5-0.5,1.5-1.5v-1.1c0-1-0.5-1.5-1.5-1.5H73.6z"
                                            />
                                            <path
                                                d="M63.3,107.6l-4.1-0.8c-1.5-0.3-2.3-0.8-2.3-2.2V104c0-2.2,0.9-3,3.8-3c2.8,0,3.8,0.8,3.8,2.8v0.6c0,0.5,0.3,0.8,0.8,0.8h2
			c0.5,0,0.8-0.3,0.8-0.8v-0.6c0-4-2.2-5.7-7.3-5.7c-4.9,0-7.4,2.1-7.4,6.1v0.6c0,3.8,1.9,4.7,4.9,5.3l4.1,0.8
			c1.6,0.3,2.3,0.8,2.3,2.2v0.6c0,2.2-1,3-4,3c-2.9,0-3.9-0.8-3.9-2.9v-0.7c0-0.5-0.3-0.8-0.8-0.8h-2.1c-0.5,0-0.8,0.3-0.8,0.8v0.7
			c0,4,2.2,5.7,7.5,5.7c5,0,7.6-2.1,7.6-6.2v-0.6C68.2,109.2,66.3,108.2,63.3,107.6z"
                                            />
                                            <path
                                                d="M26.1,104c-4.3,0-6.7,2.1-6.7,6v4c0,3.8,2.4,5.8,6.9,5.8c4.1,0,6.1-1.4,6.1-4.2v-0.1c0-0.3-0.2-0.5-0.5-0.5h-2.5
			c-0.4,0-0.5,0.1-0.5,0.4v0.1c0,1-0.9,1.5-2.7,1.5c-2.4,0-3.4-0.8-3.4-2.7v-1.2h8.5c0.8,0,1.2-0.4,1.2-1.3v-2.2
			C32.7,105.9,30.4,104,26.1,104z M29.2,110.5H23v-1.1c0-1.8,1.1-2.7,3.1-2.7s3.2,0.9,3.2,2.7V110.5z"
                                            />
                                            <path
                                                d="M6.6,98.4H1.7c-1,0-1.7,0.6-1.7,1.6v17.7c0,1,0.6,1.6,1.7,1.6h4.9c6.1,0,9.2-2.8,9.2-8.3v-4.3
			C15.8,101.2,12.7,98.4,6.6,98.4z M12.1,111.2c0,3.4-1.9,5.2-5.4,5.2h-3v-14.9h3c3.5,0,5.4,1.7,5.4,5.2V111.2z"
                                            />
                                            <path
                                                d="M43.5,104.2h-0.4c-1.5,0-2.7,0.8-3.4,2.1l-1.7-1.7c-0.6-0.6-1.3-0.6-1.9-0.1l-0.6,0.6c-0.6,0.6-0.5,1.3,0.1,1.9l1.3,1.4
			v10.1c0,0.6,0.4,1.1,1.1,1.1h1.5c0.6,0,1.1-0.4,1.1-1.1v-7.6c0-2.1,1-3.1,2.9-3.1h0.4c1,0,1.7-0.6,1.7-1.5v-0.3
			C45.6,104.8,44.9,104.2,43.5,104.2z"
                                            />
                                            <path
                                                d="M78.4,116.4h-1.2c-0.7,0-0.9-0.3-0.9-1v-9.8c0-0.9-0.4-1.3-1.3-1.3h-4.2c-0.5,0-0.8,0.3-0.8,0.8v1.4
			c0,0.5,0.3,0.8,0.8,0.8h1.8v8.4c0,2.7,1,3.8,4.2,3.8h1.4c0.5,0,0.9-0.3,0.9-0.9v-1.3C79.2,116.8,78.9,116.4,78.4,116.4z"
                                            />
                                            <path
                                                d="M208.9,104.2h-0.4c-1.5,0-2.7,0.8-3.4,2.1l-1.7-1.7c-0.6-0.6-1.3-0.6-1.9-0.1l-0.6,0.6c-0.6,0.6-0.5,1.3,0.1,1.9l1.4,1.4
			v10.1c0,0.6,0.4,1.1,1,1.1h1.5c0.6,0,1-0.4,1-1.1v-7.6c0-2.1,1-3.1,2.9-3.1h0.4c1,0,1.7-0.6,1.7-1.5v-0.3
			C211,104.8,210.3,104.2,208.9,104.2z"
                                            />
                                            <path
                                                d="M157,104c-2,0-3.5,0.7-4.5,2.1l-1.5-1.5c-0.6-0.6-1.3-0.6-1.9-0.1l-0.6,0.6c-0.6,0.6-0.5,1.3,0.1,1.9l1.4,1.4v10.1
			c0,0.6,0.4,1.1,1,1.1h1.5c0.6,0,1.1-0.4,1.1-1.1v-8.3c0-2.1,1-3.1,3-3.1c2,0,3,1.1,3,3.1v8.3c0,0.6,0.4,1.1,1,1.1h1.5
			c0.6,0,1.1-0.4,1.1-1.1v-8.3C163.2,106.2,160.8,104,157,104z"
                                            />
                                            <path
                                                d="M139.1,104c-4.4,0-6.7,2.1-6.7,6v4c0,3.8,2.4,5.8,6.9,5.8c4,0,6.1-1.4,6.1-4.2v-0.1c0-0.3-0.2-0.5-0.5-0.5h-2.5
			c-0.4,0-0.5,0.1-0.5,0.4v0.1c0,1-0.9,1.5-2.7,1.5c-2.4,0-3.4-0.8-3.4-2.7v-1.2h8.5c0.8,0,1.2-0.4,1.2-1.3v-2.2
			C145.7,105.9,143.5,104,139.1,104z M142.3,110.5H136v-1.1c0-1.8,1.1-2.7,3.1-2.7c2,0,3.1,0.9,3.1,2.7L142.3,110.5L142.3,110.5z"
                                            />
                                            <path
                                                d="M180.5,115.4V99.6c0-0.9-0.6-1.5-1.5-1.5h-0.7c-0.9,0-1.5,0.6-1.5,1.5v5.7c-0.9-0.8-2.2-1.3-3.8-1.3
			c-3.8,0-6.3,2.5-6.3,6.3v3.2c0,3.8,2.6,6.3,6.4,6.3c2.2,0,3.8-0.7,4.7-2.1l1.5,1.5c0.6,0.6,1.3,0.6,1.9,0.1l0.6-0.6
			c0.6-0.6,0.5-1.3-0.1-1.9L180.5,115.4z M176.9,113.8c0,2-1.1,3.1-3.2,3.1s-3.2-1.1-3.2-3.1v-3.9c0-2,1.1-3.1,3.2-3.1
			s3.2,1.1,3.2,3.1V113.8z"
                                            />
                                            <path
                                                d="M191.5,104c-4.4,0-6.7,2.1-6.7,6v4c0,3.8,2.4,5.8,6.9,5.8c4.1,0,6.1-1.4,6.1-4.2v-0.1c0-0.3-0.2-0.5-0.5-0.5h-2.5
			c-0.4,0-0.5,0.1-0.5,0.4v0.1c0,1-0.9,1.5-2.7,1.5c-2.4,0-3.4-0.8-3.4-2.7v-1.2h8.5c0.8,0,1.2-0.4,1.2-1.3v-2.2
			C198.1,105.9,195.8,104,191.5,104z M194.6,110.5h-6.2v-1.1c0-1.8,1-2.7,3.1-2.7c2,0,3.1,0.9,3.1,2.7V110.5L194.6,110.5z"
                                            />
                                            <path
                                                d="M125.6,110.6l-3.9-0.5c-1-0.1-1.4-0.4-1.4-1.4v-0.3c0-1.2,0.9-1.7,2.9-1.7c1.9,0,2.8,0.5,2.8,1.6v0.1
			c0,0.3,0.2,0.5,0.5,0.5h2.2c0.3,0,0.6-0.2,0.6-0.6v-0.1c0-2.8-2-4.2-6.1-4.2c-4,0-6.2,1.7-6.2,4.8v0.2c0,2.5,1.1,3.7,4,4.1
			l3.9,0.5c0.9,0.1,1.4,0.4,1.4,1.4v0.4c0,1.1-1,1.7-2.9,1.7c-2.1,0-3-0.5-3-1.6v-0.2c0-0.3-0.2-0.5-0.5-0.5h-2.2
			c-0.4,0-0.6,0.2-0.6,0.6v0.2c0,2.8,2.1,4.2,6.3,4.2c4,0,6.2-1.8,6.2-4.8v-0.3C129.5,112.2,128.4,110.9,125.6,110.6z"
                                            />
                                            <path
                                                d="M107.4,104c-2,0-3.5,0.7-4.5,2.1l-1.5-1.5c-0.6-0.6-1.3-0.6-1.9-0.1l-0.6,0.6c-0.6,0.6-0.5,1.3,0.1,1.9l1.4,1.4v10.1
			c0,0.6,0.4,1.1,1.1,1.1h1.5c0.6,0,1.1-0.4,1.1-1.1v-8.3c0-2.1,1.1-3.1,3-3.1s3,1.1,3,3.1v8.3c0,0.6,0.4,1.1,1.1,1.1h1.5
			c0.6,0,1.1-0.4,1.1-1.1v-8.3C113.5,106.2,111.1,104,107.4,104z"
                                            />
                                            <path
                                                d="M89.4,104c-2,0-3.5,0.7-4.5,2.1l-1.5-1.5c-0.6-0.6-1.3-0.6-1.9-0.1l-0.6,0.6c-0.6,0.6-0.5,1.3,0.1,1.9l1.4,1.4v10.1
			c0,0.6,0.4,1.1,1.1,1.1h1.5c0.6,0,1.1-0.4,1.1-1.1v-8.3c0-2.1,1.1-3.1,3-3.1s3,1.1,3,3.1v8.3c0,0.6,0.4,1.1,1.1,1.1h1.5
			c0.6,0,1.1-0.4,1.1-1.1v-8.3C95.6,106.2,93.2,104,89.4,104z"
                                            />
                                            <path
                                                d="M86.5,77.4c3.5,0,6.3-2.8,6.3-6.3c0-1.7-0.6-3.2-1.7-4.3v0L72,45.4c-1.1-1.2-3.5-3-3.5-3l6.7-0.3c0,0,8.5-0.3,14-6.8
			c4.6-5.5,4.5-13.5,4.5-13.5C93.5,9.8,83.6,0,71.4,0l-8.2,0c0,0,0,0,0,0s0,0,0,0c-3.1,0-5.7,2.5-5.7,5.7c0,3.1,2.5,5.7,5.7,5.7
			c0,0,0,0,0,0s0,0,0,0h4.4c8.6,0,12.6,3.2,12.9,10.5c-0.3,7.3-4.3,10.5-13,10.5l-11.3,0c-0.9,0-1.8,0.1-2.7,0.3
			c-1.8,0.5-3.4,1.8-3.4,4.4v0.3c0,1.6,0.6,2.8,2.1,4.6c0,0,25.8,29.2,29.2,33C82.7,76.4,84.5,77.4,86.5,77.4z"
                                            />
                                            <path
                                                d="M111.5,27.8c3.6,0,6.5-2.9,6.5-6.5v-10h20.6c3.1,0,5.7-2.5,5.7-5.7c0-3.1-2.5-5.7-5.7-5.7h-28c-3.1,0-5.7,2.5-5.7,5.7
			v15.6C105,24.9,107.9,27.8,111.5,27.8z"
                                            />
                                            <path
                                                d="M6.5,25c3.6,0,6.5-2.9,6.5-6.5v-7.1h26.3c3.1,0,5.7-2.5,5.7-5.7c0-3.1-2.5-5.7-5.7-5.7H5.7C2.5,0,0,2.5,0,5.7v12.8
			C0,22,2.9,25,6.5,25z"
                                            />
                                            <path
                                                d="M118.1,70.9V53.7c0-5.6,4.6-10.1,10.2-10.1l10.4,0c3.1,0,5.7-2.5,5.7-5.7c0-3.1-2.5-5.7-5.7-5.7h-11.3
			c-12.3,0-22.3,10-22.3,22.2v16.3c0,0,0,0,0,0c0,3.6,2.9,6.5,6.5,6.5C115.1,77.4,118.1,74.5,118.1,70.9
			C118,70.9,118.1,70.9,118.1,70.9z"
                                            />
                                            <path
                                                d="M33.7,43.6c3.1,0,5.7-2.5,5.7-5.7c0-3.1-2.5-5.7-5.7-5.7H6.5c-3.6,0-6.5,2.9-6.5,6.5v15.7c0,12.2,10,22.2,22.3,22.2h17.1
			l0,0c3.1,0,5.7-2.5,5.7-5.7c0-3.1-2.5-5.6-5.6-5.7c0,0,0,0,0,0H23.3c-5.6,0-10.2-4.5-10.2-10.1V43.6H33.7z"
                                            />
                                        </g>
                                    </g>
                                    <g id="Schutzzone">
                                    </g>
                                </svg>
                            </div>
                        </v-no-ssr>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <div class="bs-home__about__box__description">
                            <v-btn
                                href="https://www.erf.de"
                                target="_blank"
                                color="primary"
                                rel="noopener noreferrer"
                                title="ERF.de"
                            >
                                {{ t('async.common.button.more') }}
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="scss">
.bs-app .bs-home {
    &__about {
        position: relative;
        z-index: 3;
        padding: 102px 0 140px;
        background: map-deep-get($bs-color, greyLight);

        @include dark {
            background: map-deep-get($bs-color, dark, greyLight);
        }

        &__heading {
            color: map-deep-get($bs-color, black);

            @include dark {
                color: map-deep-get($bs-color, dark, black);
            }

            font-weight: 200;
            font-size: 48px;
            line-height: 52px;
            text-align: center;

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                padding: 0 24px;
                font-size: 40px;
                line-height: 44px;
            }
        }

        &__box {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                flex-wrap: wrap;
            }

            &:last-of-type {
                margin-top: 80px;
            }

            &__image {
                width: 80%;
                margin: 80px 20% 0;
                color: #000;

                @include dark {
                    color: white;
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    width: 100%;
                    max-width: 100%;
                    margin: 0 24px 0 0;
                    padding: 0 120px 0 24px;
                }

                svg {
                    max-width: 180px;
                    height: auto;
                }
            }

            &__description {
                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    width: 100%;
                    padding: 0 24px;
                }

                p {
                    color: map-deep-get($bs-color, black);

                    @include dark {
                        color: map-deep-get($bs-color, dark, black);
                    }

                    font-weight: 400;
                    font-size: 16px;
                    line-height: 21px;
                }

                a {
                    margin-left: 0;
                }
            }
        }
    }
}
</style>
