<template>
    <div
        v-if="items.length > 0"
        class="bs-home__sidenav"
    >
        <v-tooltip
            v-for="(item,index) in items"
            :key="index"
            location="left"
            attach="#teleported"
        >
            <template #activator="{ props }">
                <button
                    :title="item.title"
                    :class="{active: index === active}"
                    v-bind="props"
                    @click="scrollTo(item.target,item.offset,index)"
                ></button>
            </template>
            <span>{{ item.title }}</span>
        </v-tooltip>
    </div>
</template>

<script>
import {animateScroll,} from '@/assets/js/src/util/uiTools'

export default {
    name: 'HomeNavigation',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        active: 0,
        initialized: false,
        lastPageY: 0,
    }),
    created () {
        this.itemPos = []
    },
    mounted () {
        document.addEventListener('scroll', this.showScrollPosition)
    },
    beforeUnmount () {
        document.removeEventListener('scroll', this.showScrollPosition)
    },
    methods: {
        loadInitial () {
            let viewportHeight = document.documentElement.clientHeight / 3
            this.items.forEach((item, index) => {
                let target = item.target.replace('#', '')
                let elem = document.getElementById(target)

                if (typeof elem !== 'undefined' && elem !== null) {
                    this.itemPos.push({
                        offset: elem.offsetTop - item.offset - viewportHeight,
                        item: index,
                        elem: elem,
                    })
                } else if (target.indexOf('top') !== -1) {
                    this.itemPos.push({offset: 0, item: index,})
                }
            })

            if (typeof this.itemPos !== 'undefined' && this.itemPos.length > 0) {
                this.initialized = true
            }
        },
        showScrollPosition () {

            if (!this.initialized) {
                this.loadInitial()
            }

            if (this._isDestroyed) {
                document.removeEventListener('scroll', this.showScrollPosition)

                return
            }
            let pageY = window.pageYOffset

            for (let i = 0; i < this.itemPos.length; i++) {
                if (pageY >= this.itemPos[i].offset && (typeof this.itemPos[i + 1] === 'undefined' || pageY < this.itemPos[i + 1].offset)) {
                    if (this.itemPos[i].item !== this.active) {
                        this.active = this.itemPos[i].item

                        if (this.itemPos[i].elem) {
                            if (this.lastPageY <= pageY) {
                                if (this.items[this.active].show) {
                                    this.itemPos[i].elem.classList.add(this.items[this.active].show)
                                }
                            } else {
                                if (this.itemPos[i + 1] && this.items[this.itemPos[i + 1].item] && this.items[this.itemPos[i + 1].item].hide) {
                                    this.itemPos[i + 1].elem.classList.add(this.items[this.itemPos[i + 1].item].hide)
                                    this.itemPos[i + 1].elem.classList.remove(this.items[this.itemPos[i + 1].item].show)

                                    if (this.items[i + 1].duration) {
                                        setTimeout(() => {
                                            this.itemPos[i + 1].elem.classList.remove(this.items[this.itemPos[i + 1].item].hide)
                                        }, this.items[this.itemPos[i + 1].item].duration)
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.lastPageY = pageY
        },
        scrollTo (target, offset, index) {
            let elem = document.getElementById(target.replace('#', ''))

            if (typeof elem !== 'undefined' && elem !== null) {
                let header = document.querySelector('.bs-header-wrapper')
                let banner = document.querySelector('.banner-wrapper__banner')

                if (typeof header !== 'undefined' && header !== null) {
                    offset += parseInt(header.offsetHeight)
                }

                if (typeof banner !== 'undefined' && banner !== null) {
                    offset += parseInt(banner.offsetHeight)
                }

                this.animateScroll(elem.offsetTop - offset)
                this.active = index
            } else if (target.indexOf('top') !== -1) {
                this.animateScroll(0)
                this.active = 0
            }
        },
        animateScroll,
    },
}
</script>

<style lang="scss">
    .bs-app .bs-home {
        &__sidenav {
            position: fixed;
            top: 50%;
            right: 42px;
            z-index: 9990;
            transform: translateY(-50%);

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                display: none !important;
            }

            button {
                display: block;
                width: 16px;
                height: 16px;
                margin-bottom: 6px;
                background: #fff;
                border: 1px solid map-deep-get($bs-color, greyDark);
                border-radius: 14px;
                opacity: .5;
                transition: opacity .3s ease-in-out;

                &.active, &:hover {
                    opacity: 1;
                }
            }
        }
    }
</style>