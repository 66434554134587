import {getActivePinia, storeToRefs,} from 'pinia'
import {usePrefetchData,} from '@/assets/js/src/util/composables/usePrefetchData'
import {useHomeStore,} from '@/assets/js/src/modules/home/_pinia/home'
import {onUnmounted,} from 'vue'

export function useHomeApi () {
    let homeStore = useHomeStore(getActivePinia())

    const {meta,} = storeToRefs(homeStore)
    const {messagesLoaded, allAsyncLoaded,} = usePrefetchData({
        actions: [
            {
                item: meta,
                action: homeStore.fetchHomeMeta,
            },
        ],
        loadAsyncMessages: [ 'common', 'home', 'contact', 'user', 'newsletter', ],
    })

    onUnmounted(()=>{
        meta.value = {}
    })

    return {
        allAsyncLoaded,
        messagesLoaded,
        meta,
    }
}