<template>
    <div
        id="bs-home__autocomplete"
        class="bs-home__autocomplete"
        :class="{'bs-home__autocomplete--banner-visible':banner}"
    >
        <home-splash :loading="loading"></home-splash>
        <div
            v-if="!loading && !progress"
            class="bs-home__autocomplete__heading-wrapper"
        >
            <transition
                name="fade"
                mode="out-in"
            >
                <div
                    v-if="status"
                    class="bs-home__autocomplete__heading-wrapper__heading"
                >
                    <h2
                        id="animate"
                        :key="'heading'"
                        v-html="heading"
                    ></h2>
                </div>
                <loading-dummy
                    v-else
                    key="heading_dummy"
                    :items-per-row="[ [ {
                        height:'80px',
                        width:'100%',
                        margin:'0 0 15px 0',
                        background: 'transparent',
                        visible: true,
                    }, ], ]"
                ></loading-dummy>
            </transition>
        </div>
        <div
            v-if="!progress"
            class="bs-home__autocomplete__description"
        >
            <template v-if="status">
                <h1 :key="'heading'">
                    {{ t('async.home.description.heading') }}
                </h1>
                <p :key="'text'">
                    {{ t('async.home.description.text') }}
                </p>
            </template>
            <template v-else>
                <loading-dummy
                    :key="'dummy_1'"
                    :items-per-row="[ [ {
                        height:'84px',
                        width:'100%',
                        margin:'0 0 0 0',
                        background: 'transparent',
                        visible: true,
                    }, ], ]"
                ></loading-dummy>
                <loading-dummy
                    :key="'dummy_2'"
                    :items-per-row="[ [ {
                        height:'16px',
                        width:'100%',
                        margin:'16px 0 3px 0',
                        background: 'transparent',
                        visible: true,
                    }, ], ]"
                ></loading-dummy>

                <loading-dummy
                    :key="'dummy_3'"
                    :items-per-row="[ [ {
                        height:'16px',
                        width:'100%',
                        margin:'0 0 3px 0',
                        background: 'transparent',
                        visible: true,
                    }, ], ]"
                ></loading-dummy>
                <loading-dummy
                    :key="'dummy_4'"
                    :items-per-row="[ [ {
                        height:'16px',
                        width:'100%',
                        margin:'0 0 3px 0',
                        background: 'transparent',
                        visible: true,
                    }, ], ]"
                ></loading-dummy>
            </template>
        </div>
        <v-progress-circular
            size="100"
            color="primary"
            indeterminate
            class="bs-home__autocomplete__progress"
            :class="{'bs-home__autocomplete__progress--transition':progress}"
        ></v-progress-circular>
        <bs-v-img
            :src="backImg"
            class="bs-home__autocomplete--img-back"
            :alt="status ? t('async.common.home.title') : ''"
            position="top center"
            cover
            @load="imageLoaded = true"
        />
    </div>
</template>

<script>
import LoadingDummy from '@/assets/js/src/components/LoadingDummy.vue'
import BsVImg from '@/assets/js/src/components/BsVImg'
import HomeSplash from '@/assets/js/src/modules/home/_components/HomeSplash.vue'
import {getActivePinia, storeToRefs,} from 'pinia'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"

const ANIMATION_START_AFTER = 0

export default {
    name: 'HomeAutocomplete',
    components: {HomeSplash, BsVImg, LoadingDummy,},
    props: {
        progress: {
            type: Boolean,
            default: false,
        },
        status: {
            type: Boolean,
            default: false,
        },
        banner: {
            type: Boolean,
            default: false,
        },
    },
    setup () {
        let activePinia = getActivePinia()

        const appUiStore = useAppUiStore(activePinia)
        const {countRouteChange,} = storeToRefs(appUiStore)

        return {
            countRouteChange,
            ...useTranslation(),
        }
    },
    data: () => ({
        running: false,
        start: 0,
        heading: '',
        word: 0,
        loading: true,
        imageLoaded: false,
        words: [],
        backImg: import.meta.glob('@/assets/images/bs-start-back.jpg',{eager:true,})['/assets/images/bs-start-back.jpg'].default,
    }),
    watch: {
        status: {
            immediate: true,
            handler: function (newVal) {
                if (newVal && Date.now() - this.start >= ANIMATION_START_AFTER && this.loading && this.imageLoaded) {
                    this.loading = false
                }

                if (newVal && !this.loading) {
                    this.words = this.t('async.home.heading_words').split('|')
                    let word = this.words[this.word]
                    this.heading = '<span>' + word + '<span class="cursor">__</span></span>'
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.animate()
                        }, 3000)
                    })
                }
            },
        },
        imageLoaded: {
            immediate: true,
            handler: function (newVal) {
                if (newVal && Date.now() - this.start >= ANIMATION_START_AFTER && this.loading && this.status) {
                    this.loading = false
                }
            },
        },
        loading: {
            immediate: true,
            handler: function (newVal) {
                if (!newVal && this.status && !this.running) {
                    this.running = true
                    this.words = this.t('async.home.heading_words').split('|')
                    let word = this.words[this.word]
                    this.heading = '<span>' + word + '<span class="cursor">__</span></span>'
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.animate()
                        }, 3000)
                    })
                }
            },
        },
    },
    created () {
        if (this.countRouteChange > 1) {
            this.loading = false
        }

        if(this.status) {
            this.words = this.t('async.home.heading_words').split('|')
            this.heading = this.words[0]
        }
    },
    mounted () {
        this.start = Date.now()

        if (this.loading) {
            setTimeout(() => {
                if (this.loading && this.status && this.imageLoaded) {
                    this.loading = false
                }
            }, ANIMATION_START_AFTER)
        }
    },
    beforeUnmount () {
        this.running = false
    },
    methods: {
        async animate () {
            this.word++
            if (this.word > this.words.length - 1) {
                this.word = 0
            }

            if (typeof document.getElementById('animate') !== 'undefined' && document.getElementById('animate') !== null) {
                let elem = document.getElementById('animate').querySelector('span')
                let oldWord = elem.innerText || elem.textContent

                await this.fadeOut(elem, oldWord)
                await this.fadeIn(elem, this.words[this.word])
                await this.sleep(3000)

                if (this.running) {
                    this.animate()
                }
            } else {
                this.running = false
            }
        },
        async fadeOut (elem, oldWord) {
            return new Promise((resolve) => {
                let interval = setInterval(() => {
                    oldWord = oldWord.substr(0, oldWord.length - 2)

                    elem.innerHTML = oldWord + '<span class="cursor">__</span>'

                    if (oldWord.length === 0) {
                        clearInterval(interval)
                        resolve()
                    }
                }, 75)

            })
        },
        async fadeIn (elem, newWord) {
            return new Promise((resolve) => {
                let count = 1

                let interval = setInterval(() => {
                    let word = newWord.substr(0, count)

                    elem.innerHTML = word + '<span class="cursor">__</span>'

                    if (newWord.length === count) {
                        clearInterval(interval)
                        resolve()
                    }
                    count++
                }, 125)

            })
        },
        sleep (time) {
            return new Promise(resolve => setTimeout(resolve, time))
        },
    },

}
</script>

<style lang="scss">
    .bs-app .bs-home {
        &__header-wrapper {
            transition: background .2s ease-in-out;

            &--top {
                background: transparent;
            }
        }

        &__autocomplete {
            position: relative;
            height: 100vh;
            height: calc(var(--vh) * 100);
            min-height: 360px;
            padding-top: map-deep-get($bs-xl, header, height);
            overflow: hidden;
            background: map-deep-get($bs-color, bs);
            transition: height .3s ease-in-out;

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                min-height: 535px;
            }

            &--banner-visible {
                min-height: #{map-deep-get($bs-xs, banner,height) + 360};
                padding-top: map-deep-get($bs-xl, header, height) + map-deep-get($bs-xl, banner, height);

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    .bs-home__autocomplete__heading-wrapper {
                        &__heading {
                            h2 {
                                @include cache((
                                    font-size: 36px,
                                    line-height: 40px,
                                ));

                                & > span {
                                    .cursor {
                                        @include cache((
                                            font-size: 30px,
                                        ));
                                    }
                                }
                            }
                        }
                    }
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) and (orientation: landscape) {
                    .bs-home__autocomplete__description {
                        top: 100%;
                        transform: translate(-50%, -40%);
                    }

                    .bs-home__autocomplete__heading-wrapper {
                        top: calc(42vh + #{map-deep-get($bs-xs, banner, height)});
                        top: calc(var(--vh) * 42 + #{map-deep-get($bs-xs, banner, height)});
                        margin-top: -8px;
                    }
                }

                @media (height <= 535px) and (orientation: portrait) {
                    &.bs-home__autocomplete-wrapper--home {
                        top: 225px;
                    }
                }
            }

            &__splash-screen {
                position: fixed;
                inset: 0;
                z-index: 10001 !important;
                background: map-deep-get($bs-color, bs);

                & > svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 640px;
                    height: auto;
                    transform: scale(1) translate(-50%, -50%);
                    transform-origin: 0;

                    @include cache((
                        opacity: 1,
                    ));

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        width: 480px;
                    }

                    &.logo__en {
                        width: 368px;

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            height: 51px;
                        }
                    }
                }
            }

            &__heading-wrapper {
                position: absolute;
                top: 42%;
                left: 50%;
                z-index: 2;
                width: calc(100% - 50px);
                max-width: 670px;
                transform: translate(-50%, -100%);

                @media (orientation: landscape) and (max-width: #{map-deep-get($bs-xs, breakpointValue)}px){
                    top: 29%;
                }

                @media (orientation: portrait) and (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    top: 42%;
                }

                &__heading {
                    display: flex;
                    flex-shrink: 0;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: flex-start;

                    h2 {
                        color: #fff;
                        font-weight: normal;
                        font-size: 68px;
                        white-space: nowrap;
                        vertical-align: middle;

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            @include cache((
                                font-size: 36px,
                                line-height: 40px,
                            ));
                        }

                        @media (orientation: landscape) and (height <= 530px) {
                            font-size: 36px;
                            line-height: 40px;
                        }

                        & > span {
                            font-weight: 700;

                            .cursor {
                                display: inline-block;
                                margin-left: 12px;
                                color: map-deep-get($bs-color, bsHighlight);
                                font-size: 60px;
                                transform: scaleX(.8);
                                animation: blink .7s linear infinite;

                                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                                    @include cache((
                                        font-size: 30px,
                                    ));
                                }

                                @media (orientation: landscape) and (height <= 530px) {
                                    font-size: 30px;
                                }

                                @keyframes blink {
                                    50% {
                                        opacity: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &__description {
                position: absolute;
                top: 115%;
                left: 50%;
                z-index: 2;
                width: 100%;
                max-width: 670px;
                margin: -15% 0 0;
                padding: 48px;
                background: rgba(map-deep-get($bs-color, bs), .7);
                border-radius: map-deep-get($bs-xl, autocomplete, inputRadius);
                transform: translate(-50%, -100%);

                @media (orientation: portrait) and (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    top: 100%;
                    width: calc(100% - 50px);
                    padding: 12px;
                }

                @media (height >= 640px) and (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    padding: 24px;
                }

                @media (width <= 820px) and (height >= 800px){
                    top: 85%;
                }

                @media (orientation: landscape) and (max-width: #{map-deep-get($bs-xs, breakpointValue)}px){
                    top: 85%;
                    width: calc(100% - 50px);
                    padding: 12px;
                }

                @media (orientation: landscape) and (height <= 530px) {
                    top: 128%;
                    width: calc(100% - 50px);
                    padding: 12px;
                }

                h1 {
                    color: #fff;
                    font-weight: 200;
                    font-size: 48px;
                    line-height: 57px;

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        font-size: 30px;
                        line-height: 35px;
                    }

                    @media (orientation: landscape) and (height <= 530px) {
                        font-size: 30px;
                        line-height: 35px;
                    }
                }

                p {
                    margin: 16px 0 0;
                    color: #fff;
                    font-weight: normal;
                    line-height: 19px;
                    hyphens: auto;
                }
            }

            &-wrapper {
                &--home {
                    position: absolute;
                    top: 42vh;
                    top: calc(var(--vh) * 42);
                    left: 50%;
                    z-index: 9989;
                    width: calc(100% - 50px);
                    max-width: 670px;
                    margin: 0;
                    transform: translateX(-50%);
                    transition: opacity .2s ease-in-out;

                    @include cache((
                        opacity: 1,
                    ));

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        left: calc(50% - 12px);
                        width: calc(100% - 74px); // Für SearchFilter-Icon Platz schaffen

                        .autocomplete-elem-wrapper.text-format-wrapper {
                            right: -44px !important;
                        }
                    }

                    &.bs-header__autocomplete-wrapper--active {
                        @include cache((
                            z-index: 9999,
                        ));
                    }

                    &--hidden {
                        @include cache((
                            opacity: 0,
                        ));

                        transition-delay: 0s;
                    }

                    &-top {
                        position: fixed;
                        left: 300px;
                        z-index: 9991;
                        margin: 0;
                        transform: translateX(0%);
                        transition: .1s;

                        @include cache((
                            opacity: 1,
                        ));

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            left: 45px;
                            margin: 0 5px 0 15px;
                        }

                        &.bs-header__autocomplete-wrapper--active {
                            @include cache((
                                z-index: 9999,
                            ));
                        }
                    }

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) and (orientation: landscape) {
                        &.bs-header__autocomplete-wrapper--banner-visible {
                            top: calc(42vh + #{map-deep-get($bs-xs, banner, height)});
                            top: calc(var(--vh) * 42 + #{map-deep-get($bs-xs, banner, height)});
                        }
                    }

                    & ~ .bs-header__option-wrapper {
                        position: absolute;
                        top: 42vh;
                        top: calc(var(--vh) * 42);
                        right: auto;
                        left: 0;
                        z-index: 9988;
                        width: calc(100vw - calc(100vw - calc(100% - 50px)) / 2 + 50px);
                        max-width: calc(100vw - calc(100vw - 670px) / 2 + 50px);

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            right: 4px;
                            left: auto;
                            width: 100%;
                        }
                    }
                }

                &--hide {
                    @include cache((
                        opacity: 0,
                    ));
                }

                &--transition {
                    max-width: 0;
                    overflow: hidden;

                    @include cache((
                        opacity: 0,
                    ));
                }
            }

            &--img-back {
                position: fixed;
                top: 0;
                z-index: 1;
                min-width: 100vw;
                height: 100vh;
            }

            &__progress {
                position: fixed;
                top: 50%;
                left: 50%;
                z-index: -1;
                transform: translate(-50%, 50%);
                transition: opacity .2s;

                @include cache((
                    opacity: 0,
                ));

                &--transition {
                    @include cache((
                        opacity: 1,
                        z-index: 9999,
                    ));
                }
            }
        }
    }
</style>
