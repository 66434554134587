<template>
    <div
        id="contact"
        class="bs-home__contact"
    >
        <div
            class="d-flex fill-height"
        >
            <v-col
                cols="12"
                md="8"
                offset-md="2"
                class="bs-home__contact__form__wrapper flex-shrink-1"
            >
                <h2>
                    {{ t('async.home.contact.heading') }}
                </h2>
                <p>{{ t('async.home.contact.description') }}</p>
                <v-form
                    id="contact_form"
                    ref="form"
                    v-model="valid"
                    class="bs-home__contact__form"
                >
                    <div
                        class="d-flex flex-wrap fill-height"
                    >
                        <v-col
                            cols="12"
                            md="6"
                            class="bs-home__contact__form__name"
                        >
                            <v-text-field
                                v-model="name"
                                variant="underlined"
                                color="primary"
                                :label="t('async.contact.form.name')"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            class="bs-home__contact__form__email"
                        >
                            <v-text-field
                                v-model="email"
                                :rules="emailRules"
                                :label="t('async.contact.form.email')"
                                variant="underlined"
                                color="primary"
                                type="email"
                            ></v-text-field>
                        </v-col>
                    </div>
                    <div
                        class="d-flex flex-wrap fill-height"
                    >
                        <v-col
                            cols="12"
                            class="bs-home__contact__form__text pa-md-0 px-6"
                        >
                            <v-textarea
                                v-model="text"
                                :label="t('async.contact.form.text')"
                                auto-grow
                                :rules="textRules"
                                variant="underlined"
                                color="primary"
                                maxlength="5000"
                            ></v-textarea>
                            <button-group-cancel-apply
                                :apply-label="t('async.contact.form.submit')"
                                :loading="runningApiCall"
                                :disabled="runningApiCall || !valid"
                                class="bs-home__contact__form__submit"
                                @apply="submit"
                                @cancel="reset"
                            ></button-group-cancel-apply>
                        </v-col>
                    </div>
                </v-form>
            </v-col>
        </div>
    </div>
</template>

<script setup>
import ButtonGroupCancelApply from '@/assets/js/src/components/ButtonGroupCancelApply.vue'
import {ref,} from 'vue'
import {useContactForm,} from '@/assets/js/src/modules/contact/_composables/useContactForm'
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"

const form = ref(null)
const reset = function () {
    form.value.reset()
}
const {
    valid,
    email,
    emailRules,
    name,
    text,
    runningApiCall,
    textRules,
    submit,
} = useContactForm({onSuccess:reset,})
const {t,} = useTranslation()
</script>

<style lang="scss">
.bs-app .bs-home {
    &__contact {
        position: relative;
        z-index: 2;
        padding: 80px 0;
        background: map-deep-get($bs-color, bs);

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            padding: 40px 0;
        }

        h2 {
            margin: 0;
            padding: 0;
            color: map-deep-get($bs-color, black);

            @include dark {
                color: map-deep-get($bs-color, dark, black);
            }

            font-weight: 200;
            font-size: 48px;
            line-height: 57px;
            text-align: center;
        }

        p {
            margin: 32px 0 0;
            padding: 0;
            color: map-deep-get($bs-color, black);

            @include dark {
                color: map-deep-get($bs-color, dark, black);
            }

            font-weight: normal;
            font-size: 16px;
            line-height: 19px;

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                padding: 0 24px;
            }
        }

        &__form {
            margin-top: 48px;

            &__wrapper {
                padding: 80px 100px;
                background: map-deep-get($bs-color, white);
                border-radius: map-deep-get($bs-xl, autocomplete, inputRadius);

                @include dark {
                    background: map-deep-get($bs-color, dark, white);
                }

                @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                    padding: 80px 60px;
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    margin: 0 16px;
                    padding: 32px 0;
                }
            }

            &__name {
                padding: 0 10px 0 0;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    padding: 0 24px;
                }
            }

            &__email {
                padding: 0 0 0 10px;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    padding: 0 24px;
                }
            }

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                &__text {
                    padding: 0 24px;
                }
            }

            &__submit {
                #{&} {
                    justify-content: center;
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    justify-content: center;
                    padding: 0 24px;
                }

                button:first-of-type {
                    span {
                        color: map-deep-get($bs-color, overlay, greyDark);

                        @include dark {
                            color: map-deep-get($bs-color, dark, overlay, greyDark);
                        }
                    }
                }
            }
        }
    }
}
</style>