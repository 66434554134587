import {useMessagesLoaded,} from "@/assets/js/src/modules/lang/_composables/useMessagesLoaded"
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"
import {getActiveHead,} from "@/assets/js/src/util/composables/useActiveHead"
import {computed,} from "vue"
import {getActivePinia,} from "pinia"
import {genHeadOverrides,} from "@/assets/js/src/layout/meta"

export const useHeadInfo = () => {
    const {messagesLoaded,} = useMessagesLoaded([ 'common', ])
    const {t,} = useTranslation()
    const head = getActiveHead()
    const activePinia = getActivePinia()

    const title = computed(() => {
        return messagesLoaded.value
            ? t('async.common.home.title')
            : ''
    })

    const ampCanonical = computed(() => {
        return activePinia.state.value.baseUrl + '/amp'
    })

    head.push(genHeadOverrides({title, ampCanonical,}))
}
